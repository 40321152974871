import * as React from "react";
import Section from "../components/common/section";

const AboutPage = () => (
  <Section>
    <div>
      <p>
        In PAPIO, spoken words and their visual representation are unified into
        a cohesive experience.
      </p>
      <p>
        Currently the transcriptions are 100% automatically generated from
        Amazon Web Services Transcribe Service. These generated transcriptions
        can be very inaccurate at times and I plan on making tools so that
        corrections can easily be made. But it will remain like this for now.
      </p>
      <p>
        The content comes from interviews that I've conducted in recent years.
        That's likely what it will continue to be going forward but it might
        expand into other areas.
      </p>
      <p>
        - <a href="https://josephweidinger.com">Joseph Weidinger</a>
      </p>
    </div>
  </Section>
);

export default AboutPage;
